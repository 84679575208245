.root {
  position: relative;
  display: inline-block;
}

.label {
  composes: marketplaceSearchFilterLabelFontStyles from global;
  letter-spacing: 0.2px;
  cursor: pointer;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;

  border: none;
  background: transparent;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
    /* background-color: var(--matterColorLight); */
    border-color: transparent;
    text-decoration: none;
    /* box-shadow: var(--boxShadowFilterButton); */
  }
}

.labelSelected {
  composes: marketplaceSearchFilterLabelFontStyles from global;
  letter-spacing: 0.2px;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  /* border: 1px solid var(--marketplaceColor); */
  border: none;
  background: transparent;
  cursor: pointer;
  font-style: italic;
  font-weight: var(--fontWeightSemiBold);
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    /* border: 1px solid var(--marketplaceColorDark); */
    border: none;
    background: transparent;
    box-shadow: none;
  }
}
