@import '../../styles/customMediaQueries.css';

.root {
}

.icon {
  display: none;

  @media (--viewportMedium) {
    display: block;
    margin-bottom: 36px;
  }
}

.heading {
  composes: marketplaceModalTitleStyles from global;
  margin-bottom: 20px;
}

.subHeading {
  font-weight: var(--fontWeightSemiBold);
  font-size: 14px;
  margin-top: 20px;
  margin-bottom: 0;
}

.field {
  margin: 10px 0 10px 0;

  @media (--viewportMedium) {
    margin: 10px 0 10px 0;
  }
}

.field textarea {
  line-height: 1.5;
  padding-bottom: 5px;
}

.error {
  color: var(--failColor);
  margin-top: 0;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}
